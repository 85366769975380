<template>
  <div>

    <HeaderMain />

    <!-- КАК НЕ СТАТЬ ЖЕРТВОЙ -->
    <div class="section color" style="margin-bottom: 30px;">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pb-0 pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum" style="
        grid-column-gap: unset;
        position: relative;
        overflow: hidden;
        background-color: #F3F3F3;">


          <!-- Картинка в углу -->
          <img src="../../../../../public/images/scammer.png" alt="map dot"
               style="position: absolute;
                 bottom: 0;
                 right: 0;
                 left: 0;
                 margin: 0 auto;
                 max-width: 90%;
                 max-height: 80%;"
          >

          <!-- блок 1 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
            ">
            <div class="w-layout-vflex tittle"
                 style="
                 z-index: 1;
                 justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title">
                <h1 class="heading">Как не стать жертвой<br>мошенников</h1>
              </div>
              <div class="w-layout-vflex flex-good direction-adaptive"
                   style="flex-flow: row; align-items: center;"
              >
                <div class="w-layout-vflex flex-good"
                     style="flex-flow: row; align-items: center;">
                  <img class="img-60" src="../../../../../public/images/exclamation_mark.svg" alt="telegram">
                  <h1 class="text-mini white">Будьте<br>осторожны!</h1>
                </div>
              </div>
            </div>
          </div>


          <!-- блок 2 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum right w-100"
               style="
               border-radius: unset;
               padding: 25px;
               padding-bottom: 35px;
               align-items: end;
            ">

            <div class="w-layout-vflex item _1 w-100" style="max-width: 400px;">
              <div>
                <div class="text-mini semibold" style="margin-bottom: 20px;">Коротко о важном</div>

                <div id="w-node-_8a817dec-33d8-ba64-0999-5e0ddc2f3a91-8870dddc"
                     class="grid-colum right">
                  <div class="w-layout-hflex item-row gap-1">
                    <div class="text-classic">
                      <img class="img-40" src="../../../../../public/images/numbers/1.svg" alt="number1">
                    </div>
                    <div class="w-layout-vflex item-col center gap-2">
                      <h2 class="heading h4">Не отвечайте на подозрительные Email или SMS-сообщения</h2>
                    </div>
                  </div>

                  <div class="w-layout-hflex item-row gap-1">
                    <div class="text-classic">
                      <img class="img-40" src="../../../../../public/images/numbers/2.svg" alt="number2">
                    </div>
                    <div class="w-layout-vflex item-col center gap-2">
                      <h2 class="heading h4">Используйте надежные платежные системы</h2>
                    </div>
                  </div>

                  <div class="w-layout-hflex item-row gap-1">
                    <div class="text-classic">
                      <img class="img-40" src="../../../../../public/images/numbers/3.svg" alt="number3">
                    </div>
                    <div class="w-layout-vflex item-col center gap-2">
                      <h2 class="heading h4">Не отвечайте на звонки от неизвестных номеров</h2>
                    </div>
                  </div>

                  <div class="w-layout-hflex item-row gap-1">
                    <div class="text-classic">
                      <img class="img-40" src="../../../../../public/images/numbers/4.svg" alt="number4">
                    </div>
                    <div class="w-layout-vflex item-col center gap-2">
                      <h2 class="heading h4">Никогда не сообщайте им личную информацию, такую как пароли, номера банковских карт или PIN-коды.</h2>
                    </div>
                  </div>

                  <div class="w-layout-hflex item-row gap-1">
                    <div class="text-classic">
                      <img class="img-40" src="../../../../../public/images/numbers/5.svg" alt="number5">
                    </div>
                    <div class="w-layout-vflex item-col center gap-2">
                      <h2 class="heading h4">Не переводите деньги незнакомцам</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div class="text-mini semibold" style="margin-bottom: 20px;">Ваши действия</div>
                <h2 class="heading h4">
                  Если вы стали жертвой мошенничества, немедленно свяжитесь со своей банком или финансовой организацией, чтобы заблокировать свою банковскую карту или счет. Также сообщите о мошенничестве в полицию или соответствующие органы.
                </h2>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ ОТ КРАЖИ -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81"
           class="container one w-container pt-0 pb-0"
           style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum mt-0" style="grid-column-gap: unset;">
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
               background-color: var(--green-50);
            ">
            <div class="w-layout-vflex item _1 w-100">
              <div class="text-mini semibold">Простые правила</div>
              <div class="w-100">
                <h1 class="heading">Защита<br>персональных<br>данных от<br>кражи</h1>
              </div>
            </div>

            <div class="w-layout-vflex flex-good "
                 style="
                       overflow: initial;
                       align-items: end;
                       flex-flow: row;
                       justify-content: space-between;"
            >
              <div class="w-layout-hflex flex-item-best pa-0">
                <a @click="goToWriteToUs" class="button-bust w-button">Сообщить о мошенничестве</a>
              </div>

              <h2 class="text-mini white bold text-align-right">Защити себя от<br>оформления кредитов<br>и микрозаймов<br>мошенниками</h2>
            </div>
          </div>
          <!-- инструкция для одобрения займа -->
          <div id="w-node-_8a817dec-33d8-ba64-0999-5e0ddc2f3a91-8870dddc"
               class="grid-colum right"
               style="padding: 25px 25px 35px;">
            <div class="text-mini semibold">Инструкция</div>
            <h2 class="heading h2">Чтобы помешать мошенникам, соблюдайте простые правила:</h2>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/1.svg" alt="number1">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Не публикуйте персональные данные в открытом доступе в интернете;</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/2.svg" alt="number2">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Не передавайте документы посторонним лицам без обоснованной причины;</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/3.svg" alt="number3">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Не оставляйте оригиналы и копии документов без присмотра;</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/4.svg" alt="number4">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">В случае утери паспорта — незамедлительно обратитесь в органы МВД для оформления заявления об утере паспорта для внесения его в реестр недействительных паспортов РФ;</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/5.svg" alt="number5">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Не сообщайте и не подтверждайте персональные данные посторонним лицам по телефону (мессенджерах, соцсетях и пр.);</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/6.svg" alt="number6">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Не давайте фотографировать, переписывать информацию из документов;</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/7.svg" alt="number7">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Обращайте внимание на ссылки по которым вы переходите и файлы которые скачиваете — в них могут быть вирусы;</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/8.svg" alt="number8">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Не оставляйте данные своих документов и банковских карт на подозрительных сайтах.</h2>
              </div>
            </div>

          </div>
        </div>
        <hr class="bold mx-25" style="margin-top: 30px; margin-bottom: 30px;">
      </div>
    </div>

    <!-- ПЛЮСЫ ПОЛУЧЕНИЯ -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0 pb-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum mt-0" style="grid-column-gap: unset;">

          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
            ">
            <div class="w-layout-vflex item w-100">
              <div class="text-mini semibold">Способы обнаружения</div>
              <h2 class="heading h2">Плюсы получения займа<br>на банковскую карту:</h2>

              <p class="text-mini white">
                Мы рекомендуем регулярно запрашивать отчёт о кредитной истории.
                Законодательно закреплено право граждан 2 раза в год бесплатно запрашивать отчёт о кредитной истории.
              </p>

              <p class="text-mini white">
                Все организации выдающие кредиты и займы отправляют данные в одно или несколько сразу бюро
                кредитных историй (БКИ). Отправив запрос в одно из них вы сможете посмотреть свою кредитную историю.
              </p>
            </div>
          </div>

          <div id="w-node-_8a817dec-33d8-ba64-0999-5e0ddc2f3a91-8870dddc"
               class="grid-colum right w-100"
               style="padding: 25px 25px 35px;">

            <div class="w-layout-vflex item w-100">
              <div class="text-mini semibold">Важная информация</div>
              <h2 class="heading h2">Наиболее популярыне БКИ</h2>

              <div class="w-layout-vflex gap-10">
                <a href="https://nbki.ru/" class="text-mini blue mt-0 mb-0 pointer">Национальное бюро кредитных историй</a>
                <a href="https://scoring.ru/" class="text-mini blue mt-0 mb-0 pointer">Бюро кредитных историй «Скоринг бюро»</a>
                <a href="https://credistory.ru/" class="text-mini blue mt-0 mb-0 pointer">Объединенное Кредитное Бюро</a>
                <a href="https://www.rs-cb.ru/" class="text-mini blue mt-0 mb-0 pointer">Кредитное Бюро Русский Стандарт</a>
              </div>

            </div>

            <div class="w-layout-hflex flex-item-best w-text" style="gap: 20px;">
              <a @click="goToHelp" class="button-link">Помощь</a>
            </div>
          </div>

        </div>

      </div>
    </div>

    <!-- КНОПКИ ПЕРЕКЛЮЧЕНИЯ ТИПА -->
    <div class="section color">
      <div
          data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81"
          class="container one w-container pt-0 pb-0 flex flex-direction-row direction-adaptive"
          style="border-bottom: none;">
        <div class="w-100">
          <h2 class="text-mini white bold mt-0">Список наиболее<br>распространённых методов<br>кражи персональных данных</h2>
        </div>

        <div class="w-100">
          <div class="w-layout-hflex flex-item-best pa-0">
            <a
                id="openLabel"
                @click="changeType(0)"
                :class="[type === 0 ? 'button-black-invert' : 'button-black']"
                class="button-bust w-button margin-adaptive">
              Телефонные мошенники
            </a>
          </div>
        </div>

        <div class="w-100">
          <div class="w-layout-hflex flex-item-best pa-0">
            <a
                id="openLabel"
                @click="changeType(1)"
                :class="[type === 1 ? 'button-black-invert' : 'button-black']"
                class="button-bust w-button margin-adaptive">
              Фейки и сайты-двойники
            </a>
          </div>
        </div>

        <div class="w-100">
          <div class="w-layout-hflex flex-item-best pa-0">
            <a
                id="openLabel"
                @click="changeType(2)"
                :class="[type === 2 ? 'button-black-invert' : 'button-black']"
                class="button-bust w-button margin-adaptive">
              Вирусные письма
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- ТЕЛЕФОННЫЕ МОШЕННИКИ -->
    <div class="section color">

      <!-- ТИП 0 -->
      <div
          v-if="type === 0"
          data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pb-0 pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum" style="grid-column-gap: unset;">
          <!-- блок 1 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
               background-color: #CCDEFE;
               position: relative;
               overflow: hidden;
               min-height: 500px;
            ">
            <div class="w-layout-vflex tittle"
                 style="
                 z-index: 1;
                 justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title">
                <h1 class="heading">Телефонные<br>мошенники</h1>
              </div>
              <div class="w-layout-vflex flex-good"
                   style="flex-flow: row;"
              >
                <h1 class="text-mini white">Используют различные<br>уловки и методы<br>психологического<br>давления</h1>
              </div>
            </div>

            <!-- Картинка в углу -->
            <img src="../../../../../public/images/scammer_2.png" alt="scammer"
                 style="position: absolute;
                 bottom: 0;
                 max-height: 75%;
                 right: 0;"
            >
          </div>
          <!-- блок 2 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum right w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
            ">
            <div class="w-layout-vflex item _1 w-100">
              <div class="text-mini semibold">Описание</div>

              <h2 class="heading h4">Телефонные мошенники придумывают различные сценарии для кражи персональных данных и используют методы психологического давления, чтобы человек не успел принять обдуманное решение. Не торопитесь выполнять указания звонившего.</h2>

              <div class="w-layout-hflex item-row gap-1" style="margin-top: 20px;">
                <div class="text-classic">
                  <img class="img-20" src="../../../../../public/images/exclamation_mark.svg" alt="exclamation">
                </div>
                <div class="w-layout-vflex item-col center gap-2">
                  <h2 class="heading h4 accent-color">Если во время разговора неустановленное лицо просит назвать реквизиты банковских карт, PIN-коды, а также иную личную информацию, то необходимо срочно закончить разговор.</h2>
                </div>
              </div>

              <h2 class="heading h4">При любых подозрениях откажитесь от взаимодействия с посторонним лицом и самостоятельно свяжитесь с организацией, от имени которой оно обращается.</h2>

            </div>
          </div>
        </div>
      </div>

      <!-- ТИП 1 -->
      <div
          v-if="type === 1"
          data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pb-0 pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum" style="grid-column-gap: unset;">
          <!-- блок 1 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
               background-color: var(--orange-50);
               position: relative;
               overflow: hidden;
               min-height: 500px;
            ">
            <div class="w-layout-vflex tittle"
                 style="
                 z-index: 1;
                 justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title">
                <h1 class="heading">Фейки и сайты-<br>двойники</h1>
              </div>
              <div class="w-layout-vflex flex-good"
                   style="flex-flow: row;"
              >
                <h1 class="text-mini white">Создают очень похожие<br>на оригинальные сайты<br>и фейковые страницы<br>в социальных сетях</h1>
              </div>
            </div>

            <!-- Картинка в углу -->
            <img src="../../../../../public/images/scammer_3.png" alt="scammer"
                 style="position: absolute;
                 bottom: 0;
                 max-height: 75%;
                 right: 0;"
            >
          </div>
          <!-- блок 2 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum right w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
            ">
            <div class="w-layout-vflex item _1 w-100">
              <div class="text-mini semibold">Описание</div>

              <h2 class="heading h4">Мошенники могут создать полную копию сайта или страницы в социальных сетях.</h2>

              <div class="w-layout-hflex item-row gap-1" style="margin-top: 20px;">
                <div class="text-classic">
                  <img class="img-20" src="../../../../../public/images/exclamation_mark.svg" alt="exclamation">
                </div>
                <div class="w-layout-vflex item-col center gap-2">
                  <h2 class="heading h4 accent-color">Всегда обращайте внимание на адрес сайта. В нём может быть изменена одна буква или отличаться доменая зона (.com вместо .ru).</h2>
                </div>
              </div>

              <h2 class="heading h4">Если сайт или страница в соцсети, а также предлагаемые на ней условия выглядят подозрительно, немедленно покиньте её.</h2>

              <h2 class="heading h4">В случае обнаружения сайта или страницы в соцсетях, использующей логотип или графические изображения с сайта zaymer.ru, просим сообщить об этом по телефону 8(800) 7070 247 (круглосуточно, бесплатно).</h2>

            </div>
          </div>
        </div>
      </div>

      <!-- ТИП 2 -->
      <div
          v-if="type === 2"
          data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pb-0 pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum" style="grid-column-gap: unset;">
          <!-- блок 1 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
               background-color: #F3F3F3;
               position: relative;
               overflow: hidden;
               min-height: 500px;
            ">
            <div class="w-layout-vflex tittle"
                 style="
                 z-index: 1;
                 justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title">
                <h1 class="heading">Вирусные<br>письма</h1>
              </div>
              <div class="w-layout-vflex flex-good"
                   style="flex-flow: row;"
              >
                <h1 class="text-mini white">Рассылают электронные<br>письма с призывом открыть<br>вложенный файл</h1>
              </div>
            </div>

            <!-- Картинка в углу -->
            <img src="../../../../../public/images/scammer_4.png" alt="scammer"
                 style="position: absolute;
                 bottom: 0;
                 max-height: 75%;
                 right: 0;"
            >
          </div>
          <!-- блок 2 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum right w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
            ">
            <div class="w-layout-vflex item _1 w-100">
              <div class="text-mini semibold">Описание</div>

              <h2 class="heading h4">Мошенники рассылают электронные письма от имени государственных учреждений, интернет-магазинов, банков, сайтов знакомств и т.п. Они призывают открыть вложенный в письме файл или перейти по ссылке.</h2>

              <div class="w-layout-hflex item-row gap-1" style="margin-top: 20px;">
                <div class="text-classic">
                  <img class="img-20" src="../../../../../public/images/exclamation_mark.svg" alt="exclamation">
                </div>
                <div class="w-layout-vflex item-col center gap-2">
                  <h2 class="heading h4 accent-color">Ссылка или файл как правило содержат вирус, который даёт мошенникам полный доступ ко всем данным на вашем устройстве.</h2>
                </div>
              </div>

              <h2 class="heading h4">Фишинговые письма могут содержать предложения о покупке товаров со скидкой, уведомления о выигрышах, предложения работы без специальных знаний, информацию о претензиях со стороны госучреждений, подозрительные ссылки и адреса отправителей.</h2>

              <h2 class="heading h4">Никогда не переходите по ссылкам и не открывайте вложенные файлы если письмо вам кажется подозительным.</h2>

            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- КОЛОБОК В ТВОЁМ ТЕЛЕФОНЕ (2) -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-1-colum" style="grid-column-gap: unset;">
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               position: relative; overflow: hidden;
               padding: 25px 30px 35px 25px;
               border-radius: unset;
               background: linear-gradient(135deg, #005BFA 0%, #005BFA 32%, #CFDCF0 90%);
               background-position: 50% 0;
               background-size: 150% 150%;">
            <div class="w-layout-vflex tittle"
                 style="justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title w-100" style="z-index: 1;">
                <div class="flex w-100 space-between" style="gap: 10px;">
                  <h1 class="heading" style="color: #fff;">Колобок в твоём<br>телефоне</h1>
                  <img alt="install" src="../../../../../public/images/google_play.svg" class="install-image">
                </div>
              </div>

              <!-- Картинка в углу -->
              <img src="../../../../../public/images/money.png" alt="money"
                   class="money-width-2"
              >

              <!-- блок разных вариантов установки, появляется на мобилках -->
              <div class="w-layout-vflex flex-good hidden-install"
                   style="
                       align-items: end;
                       flex-flow: row;
                       z-index: 2;
                       justify-content: space-around;"
              >
                <div>
                  <img height="60px" alt="googleplay" src="../../../../../public/images/google_play.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer, HeaderMain } from '/src/app/shared/components';
import loadjs from 'loadjs';

export default {
  name: 'Scammers',
  data() {
    return {
      type: 0
    };
  },
  components: {
    Footer,
    HeaderMain
  },
  created() {
  },
  computed: {
  },
  methods: {
    goToWriteToUs() {
      this.$router.push('/write-to-us');
    },
    changeType(number) {
      this.type = number;
    },
    goToHelp() {
      this.$router.push('/support');
    },
    loadScripts() {
      // Удаляем старые скрипты
      this.removeOldScripts();

      // Подключаем jQuery
      loadjs(
          "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
          {
            success: () => {
              console.log('jQuery loaded');

              // Подключаем Webflow
              loadjs('./js/webflow.js', {
                success: () => {
                  console.log('Webflow loaded');
                },
                error: () => {
                  console.error('Failed to load Webflow');
                }
              });
            },
            error: () => {
              console.error('Failed to load jQuery');
            }
          }
      );
    },
    removeOldScripts() {
      const scripts = document.querySelectorAll('script[src*="accordion.js"], script[src*="jquery-3.5.1.min.dc5e7f18c8.js"], script[src*="webflow.js"]');
      scripts.forEach(script => {
        script.parentNode.removeChild(script);
      });
    },
  },
  mounted() {
    this.loadScripts();
  },

  activated() {
    this.loadScripts();
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style>
</style>
